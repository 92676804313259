import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['captcha', 'mobile', 'btn', 'text']
  static values = {
    sending: Boolean,
    state: {
      type: Number,
      default: 1000
    }
  }
  initialize() {
    this.loadEvent = this.load.bind(this)
    this.handlerEvent = this.handler.bind(this)
    this.timer = null
    this.times = 60
  }
  connect() {
    this.btnTarget.addEventListener('click', this.loadEvent)
  }


  loadGeetest(handler) {
    window.initGeetest4({
      captchaId: '93bc482a3f5423c0fb0482a82a0f7ad7',
        product: 'bind',
    }, handler);
  }

  handler(captchaObj) {
    const self = this
    captchaObj.appendTo("#captcha");
    captchaObj.showCaptcha();

      captchaObj.onReady(function () {
      console.log('ready')
    }).onSuccess(async function () {
      var result = captchaObj.getValidate()
      const res = await fetch('/api/v1/codes', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ geetest: result, mobile: self.mobile, state: self.stateValue })
      })
      const json = await res.json()
      console.log(json)
      if (json.status === 'error') {
        alert(json.message)
        // this.reloadCaptcha()
        captchaObj.reset()
      } else {
        // alert('短信发送成功')
        // this.btnTarget.removeEventListener('click', this.loadEvent)
        // this.close()
        captchaObj.reset()
        self.sendingValue = true
        self.timer = setInterval(() => {
          self.displayText()
        }, 1000)
      }
    })
  }
  open() {
    if (this.sendingValue) {
      // alert('已发送')
      return false
    }

    if (![1000, 1001].includes(this.stateValue)) {
      alert('请求出错')
      return
    }


    if (this.mobile === '') {
      alert('请输入手机号')
      return
    }

    if (/^1[3-9]\d{9}$/.test(this.mobile) === false) {
      alert("手机号码格式不正确")
      return
    }

      // this.dispatch('open')
      // this.reloadCaptcha()
    this.loadGeetest(this.handlerEvent)
  }
  // 弹出窗口
  open2() {
    if (this.sendingValue) {
      // alert('已发送')
      return false
    }
    if (this.mobile === '') {
      alert('请输入手机号')
    } else {
      this.dispatch('open')
      this.reloadCaptcha()
    }
  }
  close() {
    this.dispatch('close')
  }

  async load(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.mobile === '') {
      alert('请输入手机号')
      return
    }
    if (this.captcha === '') {
      alert('请先验证')
      return
    }

    if (![1000, 1001].includes(this.stateValue)) {
      alert('请求出错')
      return
    }
    const result = await fetch('/api/v1/codes', {
      method: 'POST',
      body: JSON.stringify({ mobile: this.mobile, _rucaptcha: this.captcha, state: this.stateValue }),
      headers: {
        'content-type': 'application/json'
      }
    })
    const json = await result.json()
    if (json.status === 'error') {
      alert(json.message)
      captchaObj.reset()
    } else {
      // alert('短信发送成功')
      this.btnTarget.removeEventListener('click', this.loadEvent)
      this.close()
      this.sendingValue = true
      this.timer = setInterval(() => {
        this.displayText()
      }, 1000)
    }
  }

  displayText() {
    this.times -= 1
    let text = `${this.times}秒后重发`

    if (this.times == 0) {
      text = '获取短信验证码'
      clearInterval(this.timer)
      this.timer = null
      this.btnTarget.addEventListener('click', this.loadEvent)
      this.times = 60
      this.sendingValue = false
    }
    this.textTarget.innerHTML = text
  }

  // 重新加载验证码
  reloadCaptcha() {
    // this.imgTarget.setAttribute('src', '/rucaptcha/?t=' + Date.now())
    // this.captchaTarget.value = ''
  }

  get captcha() {
    return this.captchaTarget.value
  }

  get mobile() {
    return this.mobileTarget.value
  }

  disconnect() {
    clearInterval(this.timer)
    this.timer = null
  }
}
