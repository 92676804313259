import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal']

  static values = {
    autoHide: {
      type: Boolean,
      default: true
    },
    interval: {
      type: Number,
      default: 0
    }
  }

  connect() {
    const bg = document.getElementsByClassName('modal-backdrop')
    if (bg.length < 1) {
      const bg = document.createElement('div')
      bg.classList.add('modal-backdrop')
      this.bg = bg
      document.body.appendChild(bg)
      console.log(this.bg, 'bg')
    }
  }

  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    this.element.parentElement.removeAttribute('src')
    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent click
    this.modalTarget.remove()
    // this.bg.classList.add('hidden')
    // this.bg.remove()
    const bg = document.getElementsByClassName('modal-backdrop')[0]
    if (bg) {
      bg.remove()
    }
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    console.log(e.detail, 'detail')
    if (e.detail.success && this.autoHideValue) {
      this.hideModal()
      return
    }

    if (e.detail.success && this.intervalValue > 0) {
      setTimeout(() => {
        this.hideModal()
      }, this.intervalValue)
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == 'Escape') {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }
    // this.hideModal()
  }
}
