import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    console.log("tree table")
  }
  toggle(event) {
    const target = event.currentTarget
    console.log(target.dataset.id)
    const divs = document.querySelectorAll(`[data-parent-id="${target.dataset.id}"]`)
    console.log(divs)
    divs.forEach(div => {
      div.classList.toggle("hidden")
    })
  }
}
