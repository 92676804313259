import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
export default class extends Controller {
  static values = {
    url: String
  }
  connect() {
    console.log('ok', this.element)
  }

  async export() {
    // fetch(this.urlValue)
    //   .then(resp => resp.blob())
    //   .then(blob => {
    //     const url = window.URL.createObjectURL(blob)
    //     const a = document.createElement('a')
    //     a.style.display = 'none'
    //     a.href = url
    //     // the filename you want
    //     a.download = 'todo-1.xlsx'
    //     document.body.appendChild(a)
    //     a.click()
    //     window.URL.revokeObjectURL(url)
    //     alert('your file has downloaded!') // or you know, something with better UX...
    //   })
    //   .catch(() => alert('oh no!'))
    // console.log('download', this.urlValue)
    window.open(this.urlValue)

    setTimeout(() => {
      alert('导出成功')

      Turbo.visit('/ucenter/statistics/payments')
    }, 1000)
  }
}
