import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  connect() {
    // this.element.scrollIntoView()
    this.scrollToBottom()
  }

  scrollToBottom() {
    const { scrollHeight, clientHeight, offsetHeight } = this.element
    if (scrollHeight >= clientHeight) {
      this.element.scrollIntoView()
      // this.element.scrollTop = scrollHeight - clientHeight
    }
  }
}
